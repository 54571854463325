<template>
    <modal-lateral ref="modalFilterMaterials">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-11 my-4">
                <p class="input-label-top">Tipo de material</p>
                <el-select v-model="filter.id_tipo_material" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                    <el-option v-for="item in select_material_types" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-11 my-3">
                <p class="input-label-top">Rango total de de unidades</p>
                <el-slider v-model="range_value" range :min="range.min" :max="range.max" :disabled="disableSlider" />
            </div>
            <div class="col-11 my-4">
                <p class="input-label-top">Numero de referencia</p>
                <el-input v-model="filter.referencia" type="text" size="small"/>
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="$emit('clear')">Limpiar</button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="$emit('filter')">Filtrar</button>
        </div>
    </modal-lateral>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            select_material_types: 'selects/selects/select_material_types',
            range: 'obras/servicios/orden_compra/range',
        }),
        filter: {
            get(){
                return this.$store.getters['obras/servicios/orden_compra/filter']
            },
            set(val){
                this.$store.commit('obras/servicios/orden_compra/set_filter', val)
            }
        },
        range_value: {
            get(){
                return [
                    this.filter.unidades_min,
                    this.filter.unidades_max
                ]
            },
            set(val){
                this.filter.unidades_min = val[0]
                this.filter.unidades_max = val[1]
            }
        },
        disableSlider(){
            this.range.min == this.range.max
        }
    },
    methods: {
        ...mapActions({
            clearFilter: 'obras/servicios/orden_compra/clear_filter',
        }),
        toggle(){
            this.$refs.modalFilterMaterials.toggle()
        }
    }
}
</script>