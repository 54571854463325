<template>
    <modal ref="verFacturasAsociadas" titulo="Facturas asociadas" adicional="Guardar" @adicional="guardarFacturaAsociada">
        <div class="container">
            <div class="row mb-3 titulo-divisor">
                <div class="col-auto my-auto">
                    <p class="f-600 f-14 ucfirst">Facturasfffffffff</p>
                </div>
                <div class="col my-auto pl-0">
                    <hr class="my-0 bg-white" />
                </div>
                <div class="col-auto my-auto">
                    <div class="row">
                    <div class="col auto px-1 icon-option">
                        <i class="icon-plus-circle f-25 cr-pointer" />
                    </div>
                </div>
                </div>
            </div>
           <div class="row">
               <div v-for="(factura, index) in facturas" :key="index" class="col-10 mb-3" >
                    <p class="input-label-top">Factura</p>
                    <div class="d-flex icon-option">
                        <el-input v-model="model[factura.referencia]" class="w-100" size="small" placeholder="N° factura" />
                        <i class="icon-trash-can-outline f-20 ml-2 cr-pointer" @click="deleteFacture" />
                    </div>
               </div>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        facturas: { type: Array, default: () => new Array }
    },
    data(){
        return {
            datePicker: '',
            numFactura: '',
            model: {}
        }
    },
    methods: {
        toggle(){
            this.$refs.verFacturasAsociadas.toggle()
            this.facturas.forEach(factura => {
                this.model[factura.referencia] = factura.referencia
            });
        },
        guardarFacturaAsociada(){
            this.toggle()
        },
        deleteFacture(){
            
        }
    }
}
</script>

<style lang="css" scoped>
</style>
