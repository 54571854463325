<template>
    <div class="bg-f9 border p-3 br-4 position-relative">
        <ValidationObserver ref="validator">
            <el-popover v-model="show" placement="bottom" trigger="click" width="200">
                <p class="text-center text-general f-600 m-3">Factura</p>
                <hr class="bg-general m-0" />
                <div class="m-3">
                    <ValidationProvider name="numero de factura" rules="required" v-slot="{ errors }">
                        <p class="input-label-top">Número de factura</p>
                        <el-select v-model="model.id_factura" size="small" class="mb-3" clearable filterable>
                            <el-option 
                            v-for="(factura, index) in select_bills" 
                            :key="index" 
                            :label="factura.factura"
                            :value="factura.id" 
                            :disabled="selected.includes(factura.id)"
                            />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                    <button class="btn btn-general w-100 f-12" @click="asociateBill">Asociar</button>
                </div>
                <div slot="reference" class="bg-5d text-white f-600 text-center cr-pointer br-4 f-12 py-2">
                    Asociar factura
                </div>
            </el-popover>
        </ValidationObserver>
        <div class="row pt-2 px-2">
            <div v-for="(factura, index) in facturas.slice(0, total)" :key="index" class="col-6 p-1 my-1">
                <card-factura 
                :id="factura.id"
                :idFactura="factura.id_factura" 
                :reference="factura.referencia" 
                :selected="selected"
                v-on="$listeners"
                />
            </div>
        </div>
        <p v-if="moreThanFour" class="show-more cr-pointer f-12 f-500 text-right position-absolute w-fit-content" @click="$emit('showMore')">Ver más</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardFactura from './cardFactura'
const model = { id_factura: null }
export default {
    components: { CardFactura },
    props: {
        facturas: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        asociate: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            model: {...model},
            show: false,
            total: 4,
        }
    },
    computed:{
        ...mapGetters({
            select_bills: 'selects/selects/select_bills'
        }),
        moreThanFour(){
            return this.facturas.length > 4
        },
    },
    methods:{
        toggle(){
            this.show = !this.show
        },
        edit(id_factura){
            this.model.id_factura = id_factura
            this.toggle()
        },
        reset(){
            this.model = {...model}
            this.$refs.validator.reset()
        },
        async asociateBill(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            await this.asociate(this.model)

            this.show = !this.show
            this.reset()
        }
    }
}
</script>

<style scoped>
.show-more{
    right: 25px; bottom: 2px
}
</style>
<style>
.el-popover{
    padding: 0;
}
</style>