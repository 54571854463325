<template>
    <section class="obras-ver my-3 mx-4">
        <navbar-admin>
            <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button  class="btn btn-square32-5d ml-2" @click="handleUpdateService">
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2" @click="handleDeleteService">
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
            <div class="ml-2">
                <button class="btn btn-general f-12 px-4">Finalizar servicio</button>
            </div>
        </navbar-admin>
        <!-- Card de orden de compra -->
        <div class="row mb-3">
            <div class="col-md-12 col-lg-8">
                <card-orden-compra 
                :orderNumber="purchase_order.id"
                :state="purchase_order.estado"
                :date="purchase_order.created_at | helper-fecha('DD MMM. YYYY')"
                :supplier="purchase_order.proveedor"
                :value="purchase_order.valor"
                :destiny="purchase_order.destino"
                :paymentMethod="purchase_order.forma_pago"
                :service="purchase_order.servicio"
                :project="false"
                :client="false"
                :cotization="false"
                @edit="openModalFormaPago(purchase_order.id)"
                />
            </div>
            <div v-if="!isDraft" class="col-4 px-0">
                <asociar-facturas 
                ref="asociateBill" 
                :facturas="bills" 
                :selected="selected_bills" 
                @showMore="showMoreFacturas" 
                @remove="handleDeleteFactura" 
                :asociate="asociateBill"
                @update="updateBill"
                />
            </div>
        </div>
        <!-- Estado pago -->
        <div v-if="!isDraft" class="row mb-3">
            <div class="col">
                <indicador-progress-value 
                :actualValue="parseFloat(purchase_order.valor_pagado)" 
                :totalValue="parseFloat(purchase_order.valor)" 
                :lastDate="purchase_order.fecha_ultimo_pago" 
                />
            </div>
            <div class="col-auto p-0">
                <el-tooltip v-if="isPending" placement="bottom" effect="light" visible-arrow>
                    <template #content>
                        <p class="p-2 cr-pointer item" @click="handleReportPayment">Reportar pago</p>
                        <p class="p-2 cr-pointer item" @click="handleFinishPayment">Finalizar pago</p>
                    </template>
                    <button class="btn btn-general f-12 px-4">Actualizar pago</button>
                </el-tooltip>
                <button v-if="isDelivered" class="btn bg-secondary text-white f-12 px-4 op-03 pointer-no-events cr-not-allowed">Pago finalizado</button>
            </div>
        </div>
        <!-- Titulo -->
        <div class="row gap-1">
            <div class="pr-0" :class="isDelivered ? 'col-10' : 'col-12'">
                <titulo-divisor titulo="Materiales necesarios para el servicio" class="w-100">
                    <div class="row gap-1">
                        <!-- Buscador de materiales -->
                        <input-search 
                        v-model="query" 
                        mode="client" 
                        auto-search
                        :data="purchase_order.materiales"
                        @result="filteredMaterials = $event"
                        />
                        <!-- Filtro de materiales -->
                        <filter-indicator 
                        v-if="!isDelivered" 
                        :count="filterCount" 
                        @openModalFilter="openModalFilterMaterials" 
                        @clearFilter="clean"
                        placeholder="Buscar material en la lista"
                        />
                        <!-- Boton Enviar orden -->
                        <button v-if="isDraft" class="btn btn-general f-12 px-4" @click="handleDeliverOrder">Enviar Orden</button>
                        <!-- Boton Actualizar estado -->
                        <el-tooltip v-if="isPending" placement="bottom" effect="light" visible-arrow>
                            <template #content>
                                <p class="p-2 cr-pointer item" @click="completeDeliverOrder">Entrega completa</p>
                            </template>
                            <button class="btn btn-general f-12 px-4">Actualizar estado</button>
                        </el-tooltip>
                    </div>
                </titulo-divisor>
            </div>
        </div>
        <!-- Tabla de materiales -->
        <div class="row">
            <div class="col-10">
                <tabla-general :data="filteredMaterials" :mostrarBuscador="false" :usarPaginacion="false" alturaTabla="calc(100vh - 560px)" >
                    <el-table-column label="Ref." prop="ref" min-width="50">
                        <template slot-scope="scope">
                            <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Nombre" prop="nombre" min-width="250">
                        <template slot-scope="scope">
                            <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="viewMaterialInfo(scope.row.id)">
                                {{ scope.row.nombre || '-'}}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tipo" prop="tipo" min-width="100">
                        <template slot-scope="scope">
                            <p class="f-12 text-muted2">
                                {{ scope.row.tipo }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="isDelivered" label="Valor unitario" prop="valor" min-width="150">
                        <template slot-scope="scope">
                            <p class="f-12 text-muted2">
                                {{ scope.row.valor }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total de unidades." prop="total_unidades" min-width="200" align="center">
                        <template slot-scope="scope">
                            <p class="f-12 text-muted2">
                                {{ scope.row.total_unidades }}
                            </p>
                        </template>
                    </el-table-column>
                </tabla-general>
            </div>
        </div>
        <modal-eliminar 
        ref="modalEliminarFactura" 
        title="Eliminar factura" 
        mensaje="¿Está seguro que quiere eliminar esta factura?"
        @delete="deleteBill"
        />
        <modal-filter-materials ref="modalFilterMaterials" @filter="handleFilterMaterials" @clear="clean"/>
        <modal-confirmar ref="modalEnviarOrden" titulo="Enviar ordenssss" mensaje="¿Está seguro de enviar esta orden de compra?" adicional="Enviar" @adicional="deliverOrder"/>
        <modal-ver-mas-facturas ref="modalVerMasFacturas" :facturas="bills"/>
        <modal-forma-pago ref="modalFormaPago" @save="savePaymentFees"/>
        <modal-reportar-pago ref="modalReportarPago" @report="reportPaytment"/>
        <modal-finalizar-pago ref="modalFinalizarPago" @finish="finishPayment"/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalFormaPago from './partials/modalFormaPago'
import AsociarFacturas from '~/pages/compras/components/asociarFacturas'
import modalVerMasFacturas from '~/pages/compras/partials/modalVerMasFacturas'
import modalReportarPago from '~/pages/compras/partials/modalReportarPago'
import modalFinalizarPago from '~/pages/compras/partials/modalFinalizarPago'
import modalFilterMaterials from './partials/modalFilterMaterials'
export default {
    components: { AsociarFacturas, modalFormaPago, modalReportarPago, modalFinalizarPago, modalVerMasFacturas, modalFilterMaterials },
    data(){
        return {
            id_factura: null, 
            query: '',
            materials: [],
            filteredMaterials: []
        }
    },
    computed: {
        ...mapGetters({
            workshop: 'obras/informacion/informacion',
            service: 'obras/servicios/lista_chequeo/detalleServicio',
            purchase_order: 'obras/servicios/orden_compra/purchase_order',
            bills: 'obras/servicios/orden_compra/bills',
            selected_bills: 'obras/servicios/orden_compra/selected_bills',
            purchase_states: 'compras/ordenes_compra/purchase_states',
            payment_methods: 'obras/servicios/forma_pago/payment_methods',
            total_value: 'obras/servicios/forma_pago/total_value',
            filterCount: 'obras/servicios/orden_compra/filter_count',
        }),
        isDraft(){
            return this.purchase_order.estado == 1
        },
        isPending(){
            return this.purchase_order.estado == 2
        },
        isDelivered(){
            return this.purchase_order.estado == 4
        },
        id_proyecto(){
            return this.$route.params.id_proyecto
        },
        id_control_piso(){
            return this.$route.params.id_control_piso
        },
        id_orden_compra(){
            return this.$route.params.id_orden_compra
        }
    },
    watch: {
        "purchase_order.materiales"(val){
            this.filteredMaterials = val
        }
    },
    async created(){
        this.getSelects(['Action_get_select_bills', 'Action_get_select_material_types'])
        this.getBreadcumbs(['obras.main'])
        const id_proyecto = this.id_proyecto
        const id_control_piso = this.id_control_piso
        const id_orden_compra = this.id_orden_compra
        await Promise.all([
            this.Action_get_detalle_servicio({id_proyecto, id_control_piso}),
            this.Action_get_informacion_header(this.id_proyecto),
            this.Action_get_purchase_order_bills({id_proyecto, id_control_piso, id_orden_compra}),
            this.Action_get_purchase_order_materials_range({id_proyecto, id_control_piso, id_orden_compra}),
        ])
        this.clearFilter()
        await this.filterMaterials()
        this.spliceBreadcumbs([
            {
                position: 1,
                breadcumb: {
                    name: this.workshop.nombre,
                    route: 'obras.vigentes.ver',
                    params: { id: this.workshop.id }
                }
            },
            {
                position: 2,
                breadcumb: {
                    name: this.service.seccion,
                    route: 'obras.vigentes',
                    params: {
                        id_proyecto: this.id_proyecto,
                        id_control_piso: this.id_control_piso,
                    }
                },
            },
            {
                position: 3,
                breadcumb: {
                    name: `Orden de compra ${this.purchase_order.id}`,
                    route: 'obras.vigentes.orden.compra.ver',
                    params: {
                        id_proyecto: this.id_proyecto,
                        id_control_piso: this.id_control_piso,
                        id_orden_compra: this.id_orden_compra,
                    }
                },
            }
        ])
    },
    methods: {
        ...mapActions({
            getSelects: 'selects/selects/sync',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            clearFilter: 'obras/servicios/orden_compra/clear_filter',
            Action_get_informacion_header: 'obras/informacion/Action_get_informacion_header',
            Action_get_detalle_servicio: 'obras/servicios/lista_chequeo/Action_get_detalle_servicio',
            Action_get_payment_methods: 'obras/servicios/orden_compra/Action_get_payment_methods',
            Action_save_payment_fees: 'obras/servicios/orden_compra/Action_save_payment_fees',
            Action_get_purchase_order_info: 'obras/servicios/orden_compra/Action_get_purchase_order_info',
            Action_send_purchase_order: 'obras/servicios/orden_compra/Action_send_purchase_order',
            Action_get_purchase_order_bills: 'obras/servicios/orden_compra/Action_get_purchase_order_bills',
            Action_save_purchase_order_bill: 'obras/servicios/orden_compra/Action_save_purchase_order_bill',
            Action_update_purchase_order_bill: 'obras/servicios/orden_compra/Action_update_purchase_order_bill',
            Action_delete_purchase_order_bill: 'obras/servicios/orden_compra/Action_delete_purchase_order_bill',
            Action_update_purchase_order_price: 'obras/servicios/orden_compra/Action_update_purchase_order_price',
            Action_report_purchase_order_payment: 'obras/servicios/orden_compra/Action_report_purchase_order_payment',
            Action_finish_purchase_order_payment: 'obras/servicios/orden_compra/Action_finish_purchase_order_payment',
            Action_deliver_purchase_order: 'obras/servicios/orden_compra/Action_deliver_purchase_order',
            Action_get_purchase_order_materials_range: 'obras/servicios/orden_compra/Action_get_purchase_order_materials_range',
        }),
        showMoreFacturas(){
            this.$refs.modalVerMasFacturas.toggle()
        },
        openModalFilterMaterials(){
            this.$refs.modalFilterMaterials.toggle()
        },
        handleUpdateService(){

        },
        handleDeleteService(){

        },
        handleDeleteFactura(id){
            this.id_factura = id
            this.$refs.modalEliminarFactura.toggle()
        },
        handleReportPayment(){
            this.$refs.modalReportarPago.toggle()
        },
        handleFinishPayment(){
            this.$refs.modalFinalizarPago.toggle()
        },
        handleDeliverOrder(){
            this.$refs.modalEnviarOrden.toggle()
        },
        handleFilterMaterials(){
            this.filterMaterials()
            this.$refs.modalFilterMaterials.toggle()
        },
        clean(){
            this.clearFilter()
            this.filterMaterials()
        },
        filterMaterials(){
            this.Action_get_purchase_order_info({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
        },
        async reportPaytment(payload){
            await this.Action_report_purchase_order_payment({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                payload
            })
            this.$refs.modalReportarPago.toggle()
            this.$refs.modalReportarPago.reset()
        },
        async finishPayment(){
            await this.Action_finish_purchase_order_payment({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
            this.$refs.modalFinalizarPago.toggle()
        },
        async deleteBill(){
            await this.Action_delete_purchase_order_bill({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                id_ordenes_compras_facturas: this.id_factura,
            })
        },
        async asociateBill(payload){
            await this.Action_save_purchase_order_bill({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                payload
            })
        },
        async updateBill({id_ordenes_compras_facturas, payload}){
            this.Action_update_purchase_order_bill({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                id_ordenes_compras_facturas,
                payload
            })
        },
        async deliverOrder(){
            await this.Action_send_purchase_order({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
            this.$refs.modalEnviarOrden.toggle()
        },
        async completeDeliverOrder(){
            await this.Action_deliver_purchase_order({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
        },
        async openModalFormaPago(){
            await this.Action_get_payment_methods({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
            this.$refs.modalFormaPago.toggle()
        },
        async savePaymentFees(){
            await this.Action_save_payment_fees({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                payload: this.payment_methods,
            })
            this.Action_update_purchase_order_price({
                id_orden_compra: this.id_orden_compra,
                total: this.total_value
            })
            this.$refs.modalFormaPago.toggle()
            this.$refs.modalFormaPago.reset()
        }
    }
}
</script>