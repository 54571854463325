<template>
    <div class="bg-f5 border br-4 d-middle justify-content-between px-3 f-12 f-600">
        <p class="tres-puntos -12">Factura: {{ reference }}</p>
        <el-popover placement="bottom-start" effect="light" trigger="click" visible-arrow popper-class="like-buttons popover-factura">
            <ValidationObserver ref="validator">
                <el-popover v-model="show" ref="popoverFactura" placement="bottom-end" trigger="click" width="200">
                    <p class="text-center text-general f-600 m-3">Editar factura</p>
                    <hr class="bg-general m-0" />
                    <div class="m-3">
                        <ValidationProvider name="numero de factura" rules="required" v-slot="{ errors }">
                            <p class="input-label-top">Número de factura</p>
                            <el-select v-model="model.id_factura" size="small" class="mb-3">
                                <el-option 
                                v-for="(factura, index) in select_bills" 
                                :key="index" 
                                :value="factura.id" 
                                :label="factura.factura"
                                :disabled="selected.includes(factura.id)"
                                />
                            </el-select>
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                        <button class="btn btn-general w-100 f-12" @click="handleUpdate">Guardar</button>
                    </div>
                    <p slot="reference" class="p-2 cr-pointer text-center item f-12" @click="handleEdit">Editar</p>
                </el-popover>
            </ValidationObserver>
            <p class="p-2 cr-pointer text-center item f-12" @click="$emit('remove', id)">Eliminar</p>
            <i slot="reference" class="icon-dots-vertical f-18 cr-pointer"/>
        </el-popover>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const model = { id_factura: null }
export default {
    props:{
        id: {
            type: Number,
            default: null
        },
        idFactura: {
            type: Number,
            default: null
        },
        reference: {
            type: String,
            required: true
        },
        selected: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            select_bills: 'selects/selects/select_bills'
        }),
    },
    data() {
        return {
            number: null,
            show: false,
            model: {...model}
        }
    },
    methods:{
        toggle() {
            this.show = !this.show
        },
        reset(){
            this.model = {...model}
            this.$refs.validator.reset()
            this.toggle()
        },
        handleEdit(){
            this.model.id_factura = this.id_factura
        },
        async handleUpdate(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            this.$emit('update', { id_ordenes_compras_facturas: this.id, payload: this.model})
            this.toggle()
        },
    }
}
</script>

<style lang="scss">
.popover-factura{
    min-width: 10px !important;
}
</style>